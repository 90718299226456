export default {
    "mobileTitle": "Hotelinformationen",
    "info": "Kontaktinformationen",
    "horary": "Check-in / Check-out Zeiten",
    "checkin": "Anreise ab",
    "checkout": "Abreise bis",
    "social": "Finden Sie uns auf...",
    "msgCopyPhone": "Telefonnummer erfolgreich kopiert",
    "msgCopyEmail": "E-Mail erfolgreich kopiert",
    "msgCopyAddress": "Adresse erfolgreich kopiert",
}
