export default{
    "guestLog":{
        "title":"Accesso alla webapp",
        "name":{
            "label":"Nome e cognome",
            "placeholder": "Inserisci il tuo nome e cognome",
        },
        "email":{
            "label":"Email",
            "placeholder":"Inserisci la tua email",
            "error":"Email non valida",
        },
        "button":"Successivo",
        "back_button":"Torna indietro",
        "processing":"Elaborazione",
    },
}