export default{
    "mobileTitle":"Información del hotel",
    "info":"Información de contacto",
    "horary":"Horario de Check in / Check out",
    "checkin":"Llegada a partir de las",
    "checkout":"Salida hasta las",
    "social":"Encuentranos en...",
    "msgCopyPhone":"Teléfono copiado con éxito",
    "msgCopyEmail":"Email copiado con éxito",
    "msgCopyAddress":"Dirección copiada con éxito",
    
}  