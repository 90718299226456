export default{
    "guestLog":{
        "title":"Acesso à webapp",
        "name":{
            "label":"Nome e sobrenome",
            "placeholder": "Digite seu nome e sobrenome",
        },
        "email":{
            "label":"Email",
            "placeholder":"Digite seu email",
            "error":"Email inválido",
        },
        "button":"Seguir",
        "back_button":"Voltar",
        "processing":"Processando",
    },
}
