export default {
    "breadcrumbs": {
        "experiences": "Expériences",
    },
    "card-experience": {
        "aprox": "environ",
        "min-aprox": "environ minutes",
        "and": "et",
        "recommed": "recommandé",
        "prominent": "remarquable",
        "annulation-gratuite": "annulation gratuite",
        "non-refundable": "non remboursable",
        "from":"De",
    },
    "list-page": {
        "title": "Expériences dans",
        "section-filter": {
            "label-search": "Filtrer par",
            "placeholder-input-search": "Entrez un mot-clé",
            "label-price": "Prix",
            "label-price-min": "Prix min.",
            "placeholder-price-max": "Minimum..",
            "label-price-max": "Prix max.",
            "placeholder-price-max": "Maximum..",
            "label-duration": "Durée"
        },
        "section-filter-history": {
            "btn-price": "Jusqu'à {price_max}",
            "btn-close": "Supprimer le filtre"
        },
        "btn-more-filter": "Plus de filtres",
        "btn-submit-filter": "Appliquer des filtres",
        "text-count-list": "Expériences mitigées",
        "text-count-list-mobile": "{count} expériences ont été trouvées.",
        "without-results": {
            "title": "Aucune expérience trouvée",
            "text": "Aucune expérience trouvée dans cette ville. Nous vous invitons à explorer d'autres villes, où vous découvrirez diverses expériences et activités."
        },
        "btn-load-more": "Charger plus"
    },
    "detail-page": {
        "tag-recommend": "Recommandé",
        "tag-featured": "Mis en avant",
        "text-variand-price": "Le prix varie en fonction de la taille du groupe",
        "tag-ticket-mobile": "Billet mobile",
        "title-recomendation": "Recommandation",
        "title-description": "Description",
        "title-description-mobile": "Aperçu général",
        "tooltip-language": "{language} et {numbers} de plus",
        "open-collapse-description": "Voir plus",
        "close-collapse-description": "Voir moins",
        "title-include": "Ce qui est inclus",
        "title-location": "Emplacement",
        "btn-eye-map": "Voir sur Google Maps",
        "subtitle-point-start": "Point de départ",
        "subtitle-point-end": "Point de fin",
        "ends-point-at-meeting-point": "Cette activité se termine au point de rendez-vous initial",
        "title-other-information": "Informations supplémentaires",
        "title-politic-cancelation": "Politique d'annulation",
        "text-politic-standar-1": "Vous pouvez annuler jusqu'à 24 heures avant l'expérience pour un remboursement complet.",
        "text-politic-standar-2": "Pour obtenir un remboursement complet, vous devez annuler au moins 24 heures avant le début de l'expérience.",
        "text-politic-standar-3": "Tout changement effectué moins de 24 heures avant le début de l'expérience ne sera pas accepté.",
        "text-politic-standar-4": "Si vous annulez au moins 24 heures avant le début de l'expérience, le montant payé ne sera pas remboursé.",
        "text-politic-standar-5": "Les horaires de clôture sont basés sur l'heure locale de l'expérience.",
        "text-politic-notrembolsable": "Cette expérience n'est pas remboursable et ne peut être modifiée pour aucune raison. Si vous annulez ou demandez une modification, le montant payé ne vous sera pas remboursé.",
        "opinions-word":"opinions",
        "variant-price":"Le prix varie en fonction de la taille du groupe",
        "when-experience-label":"Quand souhaitez-vous profiter de cette expérience ?",
        "maximum-reserves-label":"Vous pouvez réserver un maximum de {maxSeats} places",
        "availability-label":"Voir disponibilité",
        "free-cancellation-label":"Annulation gratuite",
        "non-refundable-cancellation-label":"Non remboursable",
    }
}