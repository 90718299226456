export default {
    "information": "Information",
    "facilities": "Facilities",
    "buttons_home": {
        "wifi": "Wifi",
        "call": "Call",
        "standards": "Standards",
        "share_stay": "Share Stay"
    },
    "utils": {
        "see_more": "See more",
        "see_all": "See all",
        "see_less": "See less",
        "copy": "Copy",
        "from": "From",
        "to": "To",
        "our_networks": "Our Networks"
    }
};
