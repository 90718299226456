export default{
    "guestLog":{
        "title":"Accéder à la webapp",
        "name":{
            "label":"Prénom et nom",
            "placeholder": "Entrez votre prénom et votre nom de famille",
        },
        "email":{
            "label":"Courrier électronique",
            "placeholder":"Entrer votre Email",
            "error":"Email invalide",
        },
        "button":"Suivant",
        "back_button":"Retourner",
        "processing":"Processing",
    }
}