export default{
    "log-or-register":{
        "title":"Inicia sesión o regístrate",
        "welcome-title": "¡Te damos la bienvenida!",
        "welcome-msg": "Disfruta de nuestra WebApp y gestiona tus estancias en cualquiera de nuestros hoteles.",
        "input": "Introduce tu correo electrónico",
        "text-error": "El formato de correo electrónico introducido no es correcto",
        "continue-button": "Continuar",
        "continue-google": "Continuar con Google",
        "continue-fb": "Continuar con Facebook"
    },
    "log":{
        "title":"Inicia sesión",
        "input-password":"Introduce una contraseña",
        "password-text-error":"La contraseña introducida es incorrecta",
        "continue-button": "Continuar",
        "forgot-question": "¿Has olvidado tu contraseña?",
        "input-show-pass": "Mostrar",
        "input-hide-pass": "Ocultar",
    },
    "register":{
        "title":"Termina de registrarte",
        "input-name":"Introduce tu nombre",
        "input-email": "Introduce tu correo electrónico",
        "input-email-error": "El formato de correo electrónico introducido no es correcto",
        "input-password": "Introduce una contraseña",
        "continue-button": "Aceptar y continuar",
        "text-info-1": "te enviará comunicaciones comerciales y promociones personalizadas por correo electrónico, SMS u otros medios electrónicos sobre productos y servicios",
        "text-info-2": "No quiero recibir mensajes promocionales.",
        "policies-text" : "Al seleccionar Aceptar y continuar, acepto la",
        "policies-title" : "Política de Privacidad.",
        "the-chain" : "La cadena",
    },
    "reset-pass":{
        "title":"Cambiar contraseña",
        "input-placeholder":"Introduce tu nueva contraseña",
        "input-input-error":"La contraseña introducida es incorrecta",
        "continue-button":"Cambiar contraseña",
        "greeting":"Hola",
        "title-reset":"Restablecer contraseña",
        "msg":"Has solicitado un enlace para restablecer la contraseña de tu cuenta en nuestra WebApp. Haz click en el siguiente botón para iniciar el proceso.",
        "link":"Restablecer contraseña",
    },
    "hotel-list":{
        "title":"Selecciona tu alojamiento",
        "amount-text":"alojamientos de la cadena",
        "stars-text":"estrellas",
        "star-text":"estrella",
    },
}  