export default {
    "header": {
        "facilities": "instalaciones",
        "explore": "explora",
        "experiences": "experiencias",
    },
    "dropdownLanguage": {
        "title": "idioma"
    },
    "footer": {
        "legal-warning": "aviso legal",
        "privacy-policy": "política de privacidad",
        "privacy-cookies": "política de cookies",
        "terms-conditions": "términos y condiciones"
    },
    "input-search": {
        "search": "Buscar",
        "placeholder": "Destinos, experiencias...",
        "from": "desde"
    }
    // más traducciones...
  };