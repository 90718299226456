export default{
    "create":{
        "title": "Erstelle deinen Aufenthalt",
        "check-date": {
            "label": "Check-in/Check-out Datum",
            "placeholder": "TT/MM/JJJJ - TT/MM/JJJJ",
        },
        "continue-button": "Erstellen und zum Aufenthalt zugreifen",
    }
}
