export default {
  "title": "Home",
  "title-welcome": "Benvenuto",
  "btn-see-all": "Vedi tutto",
  "hab": "Camera",
  "btn-more-info": "Informazioni sull'hotel",
  "stayTitle": "Soggiorno",
  "guestTitle": "Ospiti",
  "invite": "Invita",
  "section-facility": {
    "title": "strutture"
  },
  "section-what-visit": {
    "title": "cosa visitare"
  },
  "section-where-eat": {
    "title": "dove mangiare"
  },
  "section-leisure": {
    "title": "tempo libero"
  },
  "section-experience": {
        "title": "esperienze"
  },
  "card-product": {
    "featured": "in evidenza",
    "recommended": "raccomandato"
  },
  "form-survey": {
    "title": "Com'è stata la tua esperienza con noi?",
    "subtitle": "Ci piacerebbe conoscere la tua opinione, è molto importante per noi.",
    "btn-wrong": "Scorretto",
    "btn-normal": "Normale",
    "btn-good": "Molto Buono",
    "btn-next": "Successivo",
    "btn-submit": "Invia",
    "message-success-title": "Grazie per aver condiviso la tua esperienza!",
    "message-success-text": "Apprezziamo molto il tuo feedback e lavoreremo per offrirti il miglior soggiorno possibile in futuro."
  },
  "card-product":{
    "recommended": "Raccomandato",
    "featured": "In evidenza",
  },
  "inviteModal":{
    "title": "Invita ospiti",
    "inputLabel": "Invita un altro ospite",
    "inputPlaceholder": "Email dell'ospite",
    "button": "Invita",
    "textToast": "Invito inviato",
  }
};
