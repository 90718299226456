export default{
    "create":{
        "title": "Crie sua estadia",
        "check-date": {
            "label": "Data de Check-in/Check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "continue-button": "Criar e acessar a estadia",
    }
}
