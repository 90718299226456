export default {
    "breadcrumbs": {
        "explore": "Explorar",
    },
    "list-page": {
        "title": "Explorar em",
        "text-count-list": "{count} lugares encontrados",
        "text-count-list-search": "{count} lugares encontrados para",
        "text-count-list-search-active": "Pesquisar todos os lugares por",
        "text-search-not-found": "Refine sua pesquisa ou tente outra categoria",
        "btn-load-more": "Carregar mais"
    },
    "results": "Sem resultados...",
    "seaching": "Pesquisando...",
    "categorySearch": "encontrado",
    "city": "Cidade",
    "placeholder-search":"Destino da pesquisa",
    "types": {
        "que-visitar": "O que visitar",
        "donde-comer": "Onde comer",
        "ocio": "Lazer"
    },
    "categories": {
        "monumentos": "Monumentos",
        "museos": "Museus",
        "zonas-verdes": "Zonas verdes",
        "cafeterias-y-postres": "Cafeterias e sobremesas",
        "restaurantes": "Restaurantes",
        "vida-nocturna": "Vida noturna",
        "copas": "Copos",
        "compras": "Compras",
        "otros": "Outros",
    },
    "detail": {
        "titleList": "Explorar",
        "opinionsTitle": "Opiniões sobre informações turísticas",
        "opinionsWord": "Opiniões",
        "recommended": "Recomendado",
        "recommendationWord": "Recomendação",
        "featured": "Destacado",
        "callWord": "Ligar",
        "dataInterest": {
            "title": "Dados de interesse",
            "priceRange": "Faixas de preço",
            "diet": "Dietas especiais",
            "others": "Outros",
        },
        "locationWord": "Localização",
        "starWord": "Estrela",
        "starsWord": "Estrelas",
        "ratingTitle": "Classificação",
        "noComment": "Nenhum comentário escrito",
        "seeMoreComment": "Ver mais",
        "seeAllComments": "Ver todas as opiniões",
        "titleAllReviews": "Opiniões sobre informações turísticas",
        "exploreIn": "Explorar em",
        "filters": {
            "title": "Filtern nach",
            "ratings": "Avaliações",
            "distanceLogding":"Distância de hospedagem",
            "score": "Pontuação",
            "recommended": "Consigliato",
            "star": "estrela",
            "remove":"Remover tudo",
            "apply": "Aplicar filtros",
            "distante": {
                "oneKm": "Entre 1 km e 3 km",
                "threeKm": "Entre 3 km e 5 km",
                "fiveKm": "Entre 5 km e 10 km",
                "tenKm": "10 km+"
            }
        }
    },
}
