export default{
    "mobileTitle": "Informations sur l'hôtel",
    "info":"Information de contact",
    "horary":"Horaire de Check in / Check out",
    "checkin":"Arrivée à partir de",
    "checkout":"Départ jusqu'au",
    "social":"Retrouvez-nous dans...",
    "msgCopyPhone":"Phone successfully copied",
    "msgCopyEmail":"Email successfully copied",
    "msgCopyAddress":"Address successfully copied",
}  