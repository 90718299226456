export default{
    "create":{
        "title": "Créez votre séjour",
        "check-date": {
            "label": "Date de Check-in/Check-out",
            "placeholder": "jj/mm/aaaa - jj/mm/aaaa",
        },
        "continue-button": "Créer et accéder au séjour",
    }
}
