export default {
    "header": {
        "facilities": "installations",
        "explore": "explorer",
        "experiences": "expériences",
    },
    "dropdownLanguage": {
        "title": "langage"
    },
    "footer": {
        "legal-warning": "avis juridique",
        "privacy-policy": "politique de confidentialité",
        "privacy-cookies": "politique de cookies",
        "terms-conditions": "terms and Conditions"
    },
    "input-search": {
        "search": "Chercher",
        "placeholder": "Destinations, expériences...",
        "from": "depuis"
    }
    // más traducciones...
  };