export default{
    "mobileTitle":"Informações do hotel",
    "info":"Informações de contato",
    "horary":"Horários de check-in / check-out",
    "checkin":"Chegada a partir de",
    "checkout":"Partida até",
    "social":"Encontre-nos em...",
    "msgCopyPhone":"Telefone copiado com sucesso",
    "msgCopyEmail":"Email copiado com sucesso",
    "msgCopyAddress":"Endereço copiado com sucesso",
}
