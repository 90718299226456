export default{
    "create":{
        "title": "Create your stay",
        "check-date": {
            "label": "Check-in/Check-out Date",
            "placeholder": "dd/mm/yyyy - dd/mm/yyyy",
        },
        "continue-button": "Create and access the stay",
    }
}
