export default {
    'cafeterias': 'caffetterie',
    'heladerias': 'gelaterie',
    'monumentos': 'monumenti',
    'museos': 'musei',
    'zonas-verdes': 'aree verdi',
    'cafeterias-y-postres': 'caffè e dolci',
    'restaurantes': 'ristoranti',
    'tapas': 'tapas',
    'vida-nocturna': 'vita notturna',
    'copas': 'bicchieri',
    'compras': 'shopping',
    'otros': 'altri',
}