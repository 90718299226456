export default {
    "textToast": {
        "sendQueryText": "Antwort gesendet",
        "updateQueryText" : "Änderung gesendet",
    },
    "form": {
        "send": "Senden",
        "cancel" : "Stornieren",
        "title": "Anfragen",
        "hello": "Hallo",
        "whatsup": "Was gibt's?",
        "thanksAll" : "Danke für alles",
        "btn-verywrong-stay": "Sehr schlecht",
        "btn-wrong-stay": "Schlecht",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Gut",
        "btn-verygood-stay": "Sehr gut",
        "btn-verywrong-poststay": "Sehr schlecht",
        "btn-wrong-poststay": "Schlecht",
        "btn-normal-poststay": "Normal",
        "btn-good-poststay": "Gut",
        "btn-verygood-poststay": "Sehr gut",
        "poststay-bad-thanks-title": "Antwort gesendet",
        "poststay-bad-thanks-subtitle": "Vielen Dank für Ihre Zeit",
    },
    "settings": {
        "questionpre-stay": "Können wir etwas für Ihre Ankunft im Hotel tun?",
        "questionin-stay": "Wie würden Sie Ihre Zufriedenheit mit Ihrem Aufenthalt bisher bewerten?",
        "questionpost-stay": "Wie war Ihre Erfahrung mit uns?",
    },
}
