export default{
    "create":{
        "title":"Crea tu estancia",
        "check-date":{
            "label":"Fecha Check-in/ Check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "continue-button":"Crear y acceder a la estancia",
    }
}
