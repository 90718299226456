export default{
    "guestLog":{
        "title":"Acceso a la webapp",
        "name":{
            "label":"Nombre y apellidos",
            "placeholder": "Introduce tu nombre y apellidos",
        },
        "email":{
            "label":"Correo electrónico",
            "placeholder":"Introduce tu correo electrónico",
            "error":"Correo electrónico no válido"
        },
        "button":"Siguiente",
        "backButton":"Volver a atrás",
        "processing":"Procesando",
    },
}
