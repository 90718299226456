export default {
    "breadcrumbs": {
        "experiences": "Esperienze",
    },
    "card-experience": {
        "aprox": "approssimativamente",
        "min-aprox": "minuti approssimativi",
        "and": "e",
        "recommed": "raccomandato",
        "prominent": "prominente",
        "annulation-gratuite": "cancellazione gratuita",
        "non-refundable": "non rimborsabile",
        "from":"Da",
    },
    "list-page": {
        "title": "Esperienze a",
        "section-filter": {
            "label-search": "Filtra per",
            "placeholder-input-search": "Inserisci una parola chiave",
            "label-price": "Prezzo",
            "label-price-min": "Prezzo minimo",
            "placeholder-price-max": "Minimo...",
            "label-price-max": "Prezzo massimo",
            "placeholder-price-max": "Massimo...",
            "label-duration": "Durata"
        },
        "section-filter-history": {
            "btn-price": "Fino a {price_max}",
            "btn-close": "Rimuovi filtro"
        },
        "btn-more-filter": "Altri filtri",
        "btn-submit-filter": "Applica filtri",
        "text-count-list": "Esperienze miste",
        "text-count-list-mobile": "{count} Esperienze trovate.",
        "without-results": {
            "title": "Nessuna esperienza trovata",
            "text": "Nessuna esperienza trovata in questa città. Ti invitiamo a esplorare altre città, dove scoprirai varie esperienze e attività."
        },
        "btn-load-more": "Carica altro"
    },
    "detail-page": {
        "tag-recommend": "Raccomandato",
        "tag-featured": "In evidenza",
        "text-variand-price": "Il prezzo varia a seconda delle dimensioni del gruppo",
        "tag-ticket-mobile": "Biglietto mobile",
        "title-recomendation": "Raccomandazione",
        "title-description": "Descrizione",
        "title-description-mobile": "Panoramica",
        "tooltip-language": "{language} e altri {numbers}",
        "open-collapse-description": "Vedi di più",
        "close-collapse-description": "Vedi di meno",
        "title-include": "Cosa è incluso",
        "title-location": "Località",
        "btn-eye-map": "Visualizza su Google Maps",
        "subtitle-point-start": "Punto di partenza",
        "subtitle-point-end": "Punto finale",
        "ends-point-at-meeting-point": "Questa attività termina al punto d'incontro iniziale",
        "title-other-information": "Informazioni aggiuntive",
        "title-politic-cancelation": "Politica di cancellazione",
        "text-politic-standar-1": "Puoi cancellare fino a 24 ore prima dell'esperienza per un rimborso completo.",
        "text-politic-standar-2": "Per ottenere un rimborso completo, devi cancellare almeno 24 ore prima dell'orario di inizio dell'esperienza.",
        "text-politic-standar-3": "Qualsiasi modifica effettuata meno di 24 ore prima dell'orario di inizio dell'esperienza non sarà accettata.",
        "text-politic-standar-4": "Se cancelli almeno 24 ore prima dell'orario di inizio dell'esperienza, l'importo pagato non sarà rimborsato.",
        "text-politic-standar-5": "Gli orari di taglio sono basati sull'orario locale dell'esperienza.",
        "text-politic-notrembolsable": "Questa esperienza non è rimborsabile e non può essere modificata per nessun motivo. Se cancelli o richiedi una modifica, l'importo pagato non sarà rimborsato.",
        "opinions-word":"opinioni",
        "variant-price":"Il prezzo varia a seconda delle dimensioni del gruppo",
        "when-experience-label":"Quando vuoi goderti questa esperienza?",
        "maximum-reserves-label":"Puoi prenotare un massimo di {maxSeats} posti",
        "availability-label":"Vedi disponibilità",
        "free-cancellation-label":"Cancellazione gratuita",
        "non-refundable-cancellation-label":"Non rimbors"
    }
}