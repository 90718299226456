export default{
    "mobileTitle":"Hotel information",
    "info":"Contact information",
    "horary":"Check in / Check out hours",
    "checkin":"Arrival from",
    "checkout":"Departure until",
    "social":"Find us in...",
    "msgCopyPhone":"Phone successfully copied",
    "msgCopyEmail":"Email successfully copied",
    "msgCopyAddress":"Address successfully copied",
}  