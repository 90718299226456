export default {
    "account": "Mi cuenta",
    "greeting": "¡Hola, {name}!",
    "my_stays": {
        "title": "Mis estancias",
        "subtitle_active": "Gestiona tus estancias",
        "subtitle_inactive": "Crea tu próxima estancia"
    },
    "personal_info": {
        "title": "Información personal",
        "description": "Configura tu información personal"
    },
    "logout": "Cerrar sesión",
    "share_stay": "Compartir Estancia"
};
