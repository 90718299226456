export default {
    "cmn": "mandarim",
    "es": "espanhol",
    "en": "inglês",
    "hi": "hindi",
    "ar": "árabe",
    "pt": "português",
    "bn": "bengali",
    "ru": "russo",
    "ja": "japonês",
    "pa": "panjabi",
    "de": "alemão",
    "jv": "javanês",
    "wuu": "chinês Wu",
    "ms": "malaio",
    "te": "telugu",
    "vi": "vietnamita",
    "ko": "coreano",
    "fr": "francês",
    "mr": "marathi",
    "ta": "tamil",
    "ur": "urdu",
    "tr": "turco",
    "it": "italiano",
    "yue": "cantonês",
    "th": "tailandês",
    "gu": "gujarati",
    "mnp": "min bei chinês",
    "fa": "persa",
    "pl": "polonês",
    "ps": "pashto",
    "hsn": "hunanês",
    "ml": "malaiala",
    "my": "birmanês",
    "hak": "chinês Hakka",
    "uk": "ucraniano",
    "fil": "filipino",
    "ro": "romeno",
    "nl": "holandês",
    "ku": "curdo",
    "hr": "croata",
    "hu": "húngaro",
    "el": "grego",
    "cs": "tcheco",
    "sv": "sueco",
    "fi": "finlandês",
    "sk": "eslovaco",
    "sr": "sérvio",
    "no": "norueguês",
    "he": "hebraico",
    "da": "dinamarquês",
    "zh_CN": "chinês simplificado"
}
