export default {
    "cmn": "mandarin",
    "es": "espagnol",
    "en": "anglais",
    "hi": "hindi",
    "ar": "arabe",
    "pt": "portugais",
    "bn": "bengali",
    "ru": "russe",
    "ja": "japonais",
    "pa": "pendjabi",
    "de": "allemand",
    "jv": "javanais",
    "wuu": "wu chinois",
    "ms": "malais",
    "te": "télougou",
    "vi": "vietnamien",
    "ko": "coréen",
    "fr": "français",
    "mr": "marathi",
    "ta": "tamoul",
    "ur": "ourdou",
    "tr": "turc",
    "it": "italien",
    "yue": "cantonais",
    "th": "thaï",
    "gu": "gujarati",
    "mnp": "min bei chinois",
    "fa": "persan",
    "pl": "polonais",
    "ps": "pachto",
    "hsn": "hunanais",
    "ml": "malayalam",
    "my": "birman",
    "hak": "hakka chinois",
    "uk": "ukrainien",
    "fil": "filipino",
    "ro": "roumain",
    "nl": "néerlandais",
    "ku": "kurde",
    "hr": "croate",
    "hu": "hongrois",
    "el": "grec",
    "cs": "tchèque",
    "sv": "suédois",
    "fi": "finnois",
    "sk": "slovaque",
    "sr": "serbe",
    "no": "norvégien",
    "he": "hébreu",
    "da": "danois",
    "zh_CN": "chinois simplifié"
}