export default {
    "information": "Information",
    "facilities": "Installations",
    "buttons_home": {
        "wifi": "Wifi",
        "call": "Appeler",
        "standards": "Normes",
        "share_stay": "Partager Séjour"
    },
    "utils": {
        "see_more": "Voir plus",
        "see_all": "Voir tout",
        "see_less": "Voir moins",
        "copy": "Copier",
        "from": "Depuis",
        "to": "Jusqu'à",
        "our_networks": "Nos Réseaux"
    }
};
