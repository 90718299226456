export default {
    'cafeterias': 'cafeterías',
    'heladerias': 'heladerias',
    'monumentos': 'monumentos',
    'museos': 'museos',
    'zonas-verdes': 'zonas verdes',
    'cafeterias-y-postres': 'cafeterías y postres',
    'restaurantes': 'restaurantes',
    'tapas': 'tapas',
    'vida-nocturna': 'vida nocturna',
    'copas': 'cups',
    'compras': 'compras',
    'otros': 'otros'
}