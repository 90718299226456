export default {
    "header": {
        "facilities": "facilities",
        "explore": "explore",
        "experiences": "experiences",
    },
    "dropdownLanguage": {
        "title": "language"
    },
    "footer": {
        "legal-warning": "legal warning",
        "privacy-policy": "privacy Policy",
        "privacy-cookies": "cookies policy",
        "terms-conditions": "terms and Conditions"
    },
    "input-search": {
        "search": "Search",
        "placeholder": "Destinations, experiences...",
        "from": "from"
    }
    // más traducciones...
  };