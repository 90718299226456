export default{
    "create":{
        "title": "Crea il tuo soggiorno",
        "check-date": {
            "label": "Data di Check-in/Check-out",
            "placeholder": "gg/mm/aaaa - gg/mm/aaaa",
        },
        "continue-button": "Crea e accedi al soggiorno",
    }
}
