export default {
    'cafeterias': 'coffee shops',
    'heladerias': 'ice cream parlors',
    'monumentos': 'monuments',
    'museos': 'museums',
    'zonas-verdes': 'parkland',
    'cafeterias-y-postres': 'cafes and desserts',
    'restaurantes': 'restaurants',
    'tapas': 'tapas',
    'vida-nocturna': 'night life',
    'copas': 'cups',
    'compras': 'shopping',
    'otros': 'others',
}