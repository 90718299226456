export default {
    "title": "Home",
    "title-welcome": "Accueillir",
    "btn-see-all": "Voir tout",
    "hab": "Chambre",
    "btn-more-info": "Informations sur l'hôtel",
    "stayTitle":"Rester",
    "guestTitle":"Les hôtes",
    "invite":"Inviter",
    "section-facility": {
      "title": "installations"
    },
    "section-what-visit": {
      "title": "que visiter"
    },
    "section-where-eat": {
      "title": "où manger"
    },
    "section-leisure": {
      "title": "loisirs"
    },
    "section-experience": {
      "title": "expériences"
    },
    "form-survey": {
      "title": "Comment a été votre expérience avec nous ?",
      "subtitle": "Nous aimerions connaître votre avis, il est très important pour nous.",
      "btn-wrong": "Mauvais",
      "btn-normal": "Normal",
      "btn-good": "Très bien",
      "btn-next": "Suivant",
      "btn-submit": "Envoyer",
      "message-success-title": "Merci de partager votre expérience !",
      "message-success-text": "Nous apprécions grandement votre opinion et travaillerons pour vous offrir le meilleur séjour possible à l'avenir."
    },
    "card-product":{
      "recommended":"recommandé",
      "featured":"présenté",
    },
    "inviteModal":{
      "title": "Inviter des invités",
      "inputLabel": "Inviter un autre invité",
      "inputPlaceholder": "E-mail invité",
      "button": "Inviter",
      "textToast":"Invitation envoyée",
    }
  };