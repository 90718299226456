export default {
    "breadcrumbs": {
        "explore": "Explore",
    },
    "list-page": {
        "title": "Explorer dans",
        "text-count-list": "{count} lieux trouvés",
        "text-count-list-search": "{count} lieux trouvés pour",
        "text-count-list-search-active": "Rechercher dans tous les lieux",
        "text-search-not-found": "Affinez votre recherche ou essayez une autre catégorie",
        "btn-load-more": "Charger plus"
    },
    "results":'Sans résultats...',
    "seaching":'Recherche...',
    "categorySearch":'rencontrés',
    "city":"Ville",
    "placeholder-search": "Rechercher la destination",
    "types":{
        "que-visitar":"Que visiter",
        "donde-comer":"Où manger",
        "ocio":"Loisirs"
    },
    "categories":{
        "monumentos":"Monuments",
        "museos":"Musées",
        "zonas-verdes":"Zones vertes",
        "cafeterias-y-postres":"Cafétérias et desserts",
        "restaurantes":"Restaurants",
        "vida-nocturna":"vie nocturne",
        "copas":"Tasses",
        "compras":"Shopping",
        "otros":"Autres",
    },
    "detail":{
        'titleList': "Explorer",
        "opinionsTitle":"Avis sur l'information touristique",
        "opinionsWord":"Opinions",
        "recommended": "Recommandé",
        "recommendationWord": "Recommandation ",
        "featured": "En vedette",
        "callWord": "Appel",
        "dataInterest":{
            "title":"Données d'intérêt",
            "priceRange":'Rangos de prix',
            "diet":"Régimes spéciaux",
            "others":"Autres",
        },
        "locationWord": "Emplacement",
        "starWord": "Étoile",
        "starsWord": "Étoiles",
        "ratingTitle": "Pointage",
        "noComment": "Pas de commentaire",
        "seeMoreComment": "Voir plus",
        "seeAllComments": "Voir tous les avis",
        "titleAllReviews": "Avis sur l'information touristique",
        "exploreIn":"Explorez dans",
        "filters":{
            "title": "Filtrer par",
            "ratings": "Notes",
            "distanceLogding": "Distance d'hébergement",
            "recommended": "Recommandé",
            "score": "Score",
            "star": "étoile",
            "stars": "étoiles",
            "remove": "Rimuovi tutto",
            "apply": "Appliquer",
            "distante": {
                "oneKm": "Entre 1 km et 3 km",
                "threeKm": "Entre 3 km et 5 km",
                "fiveKm": "Entre 5 km et 10 km",
                "tenKm": "10 km+"
            }
        }
    },
}