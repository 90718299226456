export default {
    "breadcrumbs": {
        "experiences": "Erfahrungen",
    },
    "card-experience": {
        "aprox": "ungefähr",
        "min-aprox": "ungefähr Minuten",
        "and": "und",
        "recommed": "empfohlen",
        "prominent": "herausragend",
        "annulation-gratuite": "kostenlose Stornierung",
        "non-refundable": "nicht erstattungsfähig",
        "from":"Von",
    },
    "list-page": {
        "title": "Erfahrungen in",
        "section-filter": {
            "label-search": "Filtern nach",
            "placeholder-input-search": "Schlüsselwort eingeben",
            "label-price": "Preis",
            "label-price-min": "Min. Preis",
            "placeholder-price-max": "Minimum..",
            "label-price-max": "Max. Preis",
            "placeholder-price-max": "Maximum..",
            "label-duration": "Dauer"
        },
        "section-filter-history": {
            "btn-price": "Bis zu {price_max}",
            "btn-close": "Filter entfernen"
        },
        "btn-more-filter": "Weitere Filter",
        "btn-submit-filter": "Filter anwenden",
        "text-count-list": "Gemischte Erfahrungen",
        "text-count-list-mobile": "{count} Erfahrungen gefunden.",
        "without-results": {
            "title": "Keine Erfahrungen gefunden",
            "text": "In dieser Stadt wurden keine Erfahrungen gefunden. Wir laden Sie ein, andere Städte zu erkunden, wo Sie verschiedene Erfahrungen und Aktivitäten entdecken können."
        },
        "btn-load-more": "Mehr laden"
    },
    "detail-page": {
        "tag-recommend": "Empfohlen",
        "tag-featured": "Hervorgehoben",
        "text-variand-price": "Der Preis variiert je nach Gruppengröße",
        "tag-ticket-mobile": "Mobiles Ticket",
        "title-recomendation": "Empfehlung",
        "title-description": "Beschreibung",
        "title-description-mobile": "Überblick",
        "tooltip-language": "{language} und {numbers} mehr",
        "open-collapse-description": "Mehr sehen",
        "close-collapse-description": "Weniger anzeigen",
        "title-include": "Was ist inbegriffen",
        "title-location": "Standort",
        "btn-eye-map": "Auf Google Maps ansehen",
        "subtitle-point-start": "Startpunkt",
        "subtitle-point-end": "Endpunkt",
        "ends-point-at-meeting-point": "Diese Aktivität endet am ursprünglichen Treffpunkt",
        "title-other-information": "Weitere Informationen",
        "title-politic-cancelation": "Stornierungsbedingungen",
        "text-politic-standar-1": "Sie können bis zu 24 Stunden vor der Erfahrung stornieren, um eine vollständige Rückerstattung zu erhalten.",
        "text-politic-standar-2": "Um eine vollständige Rückerstattung zu erhalten, müssen Sie mindestens 24 Stunden vor dem Startzeitpunkt der Erfahrung stornieren.",
        "text-politic-standar-3": "Änderungen, die weniger als 24 Stunden vor dem Startzeitpunkt der Erfahrung gemacht werden, werden nicht akzeptiert.",
        "text-politic-standar-4": "Wenn Sie mindestens 24 Stunden vor dem Startzeitpunkt der Erfahrung stornieren, wird der gezahlte Betrag nicht erstattet.",
        "text-politic-standar-5": "Die Stichtagszeiten basieren auf der lokalen Zeit der Erfahrung.",
        "text-politic-notrembolsable": "Diese Erfahrung ist nicht erstattungsfähig und kann aus keinem Grund geändert werden. Wenn Sie stornieren oder eine Änderung anfordern, wird der gezahlte Betrag nicht erstattet.",
        "opinions-word":"Meinungen",
        "variant-price":"Der Preis variiert je nach Gruppengröße",
        "when-experience-label":"Wann möchten Sie diese Erfahrung genießen?",
        "maximum-reserves-label":"Sie können maximal {maxSeats} Plätze reservieren",
        "availability-label":"Verfügbarkeit sehen",
        "free-cancellation-label":"Kostenlose Stornierung",
        "non-refundable-cancellation-label":"Nicht erstattungsfähig",
    }
}
