export default {
    'cafeterias': 'les cafés',
    'heladerias': 'glaciers',
    'monumentos': 'les monuments',
    'museos': 'musées',
    'zonas-verdes': 'zones vertes',
    'cafeterias-y-postres': 'cafés et desserts',
    'restaurantes': 'restaurants',
    'tapas': 'tapas',
    'vida-nocturna': 'vie nocturne',
    'copas': 'cups',
    'compras': 'tasses',
    'otros': 'outres'
}