export default {
    "information": "Informação",
    "facilities": "Instalações",
    "buttons_home": {
        "wifi": "Wifi",
        "call": "Ligar",
        "standards": "Normas",
        "share_stay": "Compartilhar Estadia"
    },
    "utils": {
        "see_more": "Ver mais",
        "see_all": "Ver tudo",
        "see_less": "Ver menos",
        "copy": "Copiar",
        "from": "Desde",
        "to": "Até",
        "our_networks": "Nossas Redes"
    }
};
