export default {
    'cafeterias': 'Cafés',
    'heladerias': 'Eisdielen',
    'monumentos': 'Denkmäler',
    'museos': 'Museen',
    'zonas-verdes': 'Grünflächen',
    'cafeterias-y-postres': 'Cafés und Desserts',
    'restaurantes': 'Restaurants',
    'tapas': 'Tapas',
    'vida-nocturna': 'Nachtleben',
    'copas': 'Gläser',
    'compras': 'Einkaufen',
    'otros': 'Andere',
}
