export default{
    "guestLog":{
        "title":"Access to the webapp",
        "name":{
            "label":"Name and surname",
            "placeholder": "Enter your first and last name",
        },
        "email":{
            "label":"Email",
            "placeholder":"Enter your email",
            "error":"Invalid email",
        },
        "button":"Following",
        "back_button":"Go back",
        "processing":"Processing",
    },
}