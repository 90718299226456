export default {
    "cmn": "mandarino",
    "es": "spagnolo",
    "en": "inglese",
    "hi": "hindi",
    "ar": "arabo",
    "pt": "portoghese",
    "bn": "bengalese",
    "ru": "russo",
    "ja": "giapponese",
    "pa": "punjabi",
    "de": "tedesco",
    "jv": "giavanese",
    "wuu": "cinese Wu",
    "ms": "malese",
    "te": "telugu",
    "vi": "vietnamita",
    "ko": "coreano",
    "fr": "francese",
    "mr": "marathi",
    "ta": "tamil",
    "ur": "urdu",
    "tr": "turco",
    "it": "italiano",
    "yue": "cantonese",
    "th": "tailandese",
    "gu": "gujarati",
    "mnp": "min bei cinese",
    "fa": "persiano",
    "pl": "polacco",
    "ps": "pashto",
    "hsn": "hunanese",
    "ml": "malayalam",
    "my": "birmano",
    "hak": "hakka cinese",
    "uk": "ucraino",
    "fil": "filippino",
    "ro": "rumeno",
    "nl": "olandese",
    "ku": "curdo",
    "hr": "croato",
    "hu": "ungherese",
    "el": "greco",
    "cs": "ceco",
    "sv": "svedese",
    "fi": "finlandese",
    "sk": "slovacco",
    "sr": "serbo",
    "no": "norvegese",
    "he": "ebraico",
    "da": "danese",
    "zh_CN": "cinese semplificato"
}
