export default {
    'cafeterias': 'cafeterias',
    'heladerias': 'sorveterias',
    'monumentos': 'monumentos',
    'museos': 'museus',
    'zonas-verdes': 'áreas verdes',
    'cafeterias-y-postres': 'cafés e sobremesas',
    'restaurantes': 'restaurantes',
    'tapas': 'tapas',
    'vida-nocturna': 'vida noturna',
    'copas': 'copos',
    'compras': 'compras',
    'otros': 'outros',
}
