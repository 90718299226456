export default{
    "mobileTitle": "Informazioni sull'hotel",
    "info": "Informazioni di contatto",
    "horary": "Orari di check-in / check-out",
    "checkin": "Arrivo dalle",
    "checkout": "Partenza fino alle",
    "social": "Trova noi su...",
    "msgCopyPhone": "Telefono copiato con successo",
    "msgCopyEmail": "Email copiata con successo",
    "msgCopyAddress": "Indirizzo copiato con successo",
}