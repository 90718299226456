export default {
    "information": "Informazione",
    "facilities": "Strutture",
    "buttons_home": {
        "wifi": "Wifi",
        "call": "Chiamare",
        "standards": "Norme",
        "share_stay": "Condividi Soggiorno"
    },
    "utils": {
        "see_more": "Vedi di più",
        "see_all": "Vedi tutto",
        "see_less": "Vedi meno",
        "copy": "Copiare",
        "from": "Da",
        "to": "A",
        "our_networks": "Le Nostre Reti"
    }
};
