export default{
    "guestLog":{
        "title":"Zugang zur Webanwendung",
        "name":{
            "label":"Name und Nachname",
            "placeholder": "Geben Sie Ihren Vor- und Nachnamen ein",
        },
        "email":{
            "label":"E-Mail",
            "placeholder":"Geben Sie Ihre E-Mail-Adresse ein",
            "error":"Ungültige E-Mail",
        },
        "button":"Weiter",
        "back_button":"Zurückgehen",
        "processing":"Verarbeitung",
    },
}
