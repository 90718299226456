export default{
    "textToast": {
        "sendQueryText" : "Respuesta enviada",
        "updateQueryText" : "Modificación enviada",
    },
    "form": {
        "send" : "Enviar",
        "cancel" : "Cancelar",
        "title": "Feedback",
        "hello" : "Hola",
        "whatsup" : "Qué tal",
        "thanksAll" : "Gracias por todo",
        "btn-verywrong-stay": "Muy malo",
        "btn-wrong-stay": "Malo",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Bueno",
        "btn-verygood-stay": "Muy bueno",
        "btn-verywrong-poststay": "Muy mala",
        "btn-wrong-poststay": "Mala",
        "btn-normal-poststay": "Normal",
        "btn-good-poststay": "Buena",
        "btn-verygood-poststay": "Muy buena",
        "poststay-bad-thanks-title": "Respuesta enviada ",
        "poststay-bad-thanks-subtitle": "Muchas gracias por tu tiempo",
    },
    "settings":{
        "questionpre-stay" : "¿Hay algo que podamos hacer para tu llegada al hotel?",
        "questionin-stay" : "¿Cómo calificarías tu nivel de satisfacción con tu estancia hasta ahora?",
        "questionpost-stay" : "¿Cómo ha sido tu experiencia con nosotros?",
    },
}  